<template>
  <div class="card">
    <woot-message-editor
      v-model="noteContent"
      class="input--note"
      :placeholder="$t('NOTES.ADD.PLACEHOLDER')"
      :enable-suggestions="false"
    />
    <div class="footer">
      <woot-button
        color-scheme="warning"
        :title="$t('NOTES.ADD.TITLE')"
        :is-disabled="buttonDisabled"
        @click="onAdd"
      >
        {{ $t('NOTES.ADD.BUTTON') }} (⌘⏎)
      </woot-button>
        <file-upload
          ref="upload"
          v-tooltip.left="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          :accept="allowedFileTypes"
          :multiple="false"
          :drop="true"
          :drop-directory="false"
          @input-file="onUpload"
        >
          <woot-button
            class-names="button--upload"
            :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            icon="attach"
            emoji="📎"
            color-scheme="secondary"
            variant="smooth"
            size="small"
          />
        </file-upload>
        <woot-button
          v-if="file"
          class="remove--attachment clear secondary"
          icon="dismiss"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="onFileDelete"
        />
              
    </div>
  </div>
</template>

<script>
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import { hasPressedCommandAndEnter } from 'shared/helpers/KeyboardHelpers';
import FileUpload from 'vue-upload-component';
import {
  ALLOWED_FILE_TYPES,
} from 'shared/constants/messages';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import inboxMixin from 'shared/mixins/inboxMixin';
import { mixin as clickaway } from 'vue-clickaway';


export default {
  components: {
    WootMessageEditor,
    FileUpload
  },
  mixins: [clickaway, eventListenerMixins, uiSettingsMixin, inboxMixin],

  data() {
    return {
      noteContent: '',
      file: null
    };
  },

  computed: {
    buttonDisabled() {
      return this.noteContent === '' && this.file == null ? true : false;
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
  },

  mounted() {
    document.addEventListener('keydown', this.onMetaEnter);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onMetaEnter);
  },

  methods: {
    onMetaEnter(e) {
      if (hasPressedCommandAndEnter(e)) {
        e.preventDefault();
        this.onAdd();
      }
    },
    onAdd() {
      this.$emit('add', { content: this.noteContent, file: this.file });
      this.noteContent = '';
      this.file = null;
    },

    onUpload(target) {
      this.file = target.file;
    },

    onFileDelete() {
      this.file = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.input--note {
  &::v-deep .ProseMirror-menubar {
    padding: 0;
    margin-top: var(--space-minus-small);
  }

  &::v-deep .ProseMirror-woot-style {
    max-height: 36rem;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  gap: 1rem;
  width: 100%;
}

.file-uploads {
  display: flex;
  align-items: center;
} 
</style>
