import ApiClient from './ApiClient';
class ContactNotes extends ApiClient {
  constructor() {
    super('notes', { accountScoped: true });
    this.contactId = null;
  }

  get url() {
    return `${this.baseUrl()}/contacts/${this.contactId}/notes`;
  }

  get(contactId) {
    this.contactId = contactId;
    return super.get();
  }

  create(contactId, content, ticket_id, file) {
    this.contactId = contactId;

    if (file) {
      const formData = new FormData();
      formData.append('note[content]', content);
      formData.append('note[ticket_id]', ticket_id);
      formData.append('note[file]', file);

      return axios.post(this.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      return super.create({ content, ticket_id });
    }
  }

  delete(contactId, id) {
    this.contactId = contactId;
    return super.delete(id);
  }
}

export default new ContactNotes();
