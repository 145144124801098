<template>
    <woot-modal :show.sync="show" :on-close="onClose" size="modal-fit">
        <woot-button color-scheme="secondary"
          icon="dismiss"
          variant="clear"
          class="media-close"
          @click="$emit('cancel')" />
      <img
        v-if="templateIsImage(template)"
        class="image-preview"
        :src="getTemplateImage(template)"
      />
      <div v-if="templateIsDocument(template)">
        <vue-pdf-embed v-for="page in 4" :key="page" :source="getTemplateDocument(template)" :width="500" :scale="1" :page="page" />
      </div>
    </woot-modal>
  </template>
  
  <script>
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import WootButton from '../../../ui/WootButton.vue';
  export default {
    components: { VuePdfEmbed,WootButton },
    props: {
      template: {
        type: Object,
        default: () => ({}),
      },
      show: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        selectedWaTemplate: null,
      };
    },
    computed: {
      modalHeaderContent() {
        return this.selectedWaTemplate
          ? this.$t('WHATSAPP_TEMPLATES.MODAL.TEMPLATE_SELECTED_SUBTITLE', {
              templateName: this.selectedWaTemplate.name,
            })
          : this.$t('WHATSAPP_TEMPLATES.MODAL.SUBTITLE');
      },
    },
    methods: {
      templateIsImage() {
        return this.template?.components[0]?.format === 'IMAGE';
      },
      getTemplateImage() {
        return this.template?.media_url
          ? this.template?.media_url
          : this.template?.components[0]?.example?.header_handle;
      },
      templateIsDocument() {
        return this.template?.components[0]?.format === 'DOCUMENT';
      },
      getTemplateDocument() {
        return this.template?.media_url
          ? this.template?.media_url
          : "https://www.aeee.in/wp-content/uploads/2020/08/Sample-pdf.pdf";
      },
      onClose() {
        this.$emit('cancel');
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-content {
    padding: 2.5rem 3.2rem;
  }

  .media-close {
  position: absolute;
  right: var(--space-small);
  top: var(--space-small);
  z-index: 9999;
}

  .image-preview {
    width: 500px;
  }
  </style>
  