/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

const formatLocation = location => {
  const {
    route,
    street_number,
    administrative_area_level_1,
    administrative_area_level_2,
    country,
  } = location;

  return `${route ? route + ' ' : ''}${
    street_number ? street_number + ' ' : ''
  } ${street_number || route ? ', ' : ''}${administrative_area_level_2 || ''}${
    administrative_area_level_2 ? ', ' : ''
  } ${administrative_area_level_1 || ''}${
    administrative_area_level_1 ? ', ' : ''
  } ${country || ''}`;
};

export const buildCreatePayload = ({
  message,
  isPrivate,
  contentAttributes,
  echoId,
  files,
  location,
  ccEmails = '',
  bccEmails = '',
  templateParams,
  contact,
  reply_id = null,
  tag_id = null,
  url,
  type,
}) => {
  let payload;
  if ((files && files.length !== 0) || (type && url)) {
    payload = new FormData();
    if (message) {
      payload.append('content', message);
    }
    files?.forEach(file => {
      payload.append('attachments[]', file);
    });
    payload.append('private', isPrivate);
    payload.append('echo_id', echoId);
    payload.append('cc_emails', ccEmails);
    payload.append('bcc_emails', bccEmails);
    payload.append('template_params', JSON.stringify(templateParams));
    if (type) {
      payload.append('type', type);
    }
    if (url) {
      payload.append('url', url);
    }
  } else {
    payload = {
      content: contact ? contact.name : message,
      private: isPrivate,
      echo_id: echoId,
      content_attributes: contentAttributes,
      cc_emails: ccEmails,
      bcc_emails: bccEmails,
      reply_id: reply_id,
      template_params: templateParams,
      tag_id: tag_id,
      contact: contact && { ...contact, file_type: 'contact' },
      location: location && {
        name: formatLocation(location),
        file_type: 'location',
        coordinates_lat: location.latitude,
        coordinates_long: location.longitude,
        fallback_title: formatLocation(location),
        external_url: location.url,
      },
    };
  }
  return payload;
};


class MessageApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create({
    conversationId,
    message,
    private: isPrivate,
    contentAttributes,
    echo_id: echoId,
    files,
    location,
    contact,
    ccEmails = '',
    bccEmails = '',
    templateParams,
    reply_id = '',
    url,
    type,
  }) {
    const data = buildCreatePayload({
      message,
      isPrivate,
      contentAttributes,
      echoId,
      files,
      ccEmails,
      bccEmails,
      templateParams,
      location,
      contact,
      reply_id,
      url,
      type,
    });
    return axios({
      method: 'post',
      url: `${this.url}/${conversationId}/messages`,
      data,
    });
  }

  downloadFile(conversationId, messageId) {
    return axios.post(
      `${this.url}/${conversationId}/messages/${messageId}/download_file`
    );
  }

  retryMessage(payload) {
    const conversationId = payload.conversation_id;
    const messageId = payload.id;
    return axios.post(
      `${this.url}/${conversationId}/messages/${messageId}/retry`,
      payload
    );
  }

  edit({ isEdited, conversationID, messageId, ...data }) {
    return axios.put(`${this.url}/${conversationID}/messages/${messageId}`, {
      ...data,
      messageId,
      is_edited: isEdited,
    });
  }

  delete(conversationID, messageId) {
    return axios.delete(`${this.url}/${conversationID}/messages/${messageId}`);
  }

  getPreviousMessages({ conversationId, after, before }) {
    const params = { before };
    if (after && Number(after) !== Number(before)) {
      params.after = after;
    }
    return axios.get(`${this.url}/${conversationId}/messages`, { params });
  }

  translateMessage(conversationId, messageId, targetLanguage) {
    return axios.post(
      `${this.url}/${conversationId}/messages/${messageId}/translate`,
      {
        target_language: targetLanguage,
      }
    );
  }
}

export default new MessageApi();
