<template>
    <div class="card task-wrap" :class="deadlineExceeded">
      <div class="header">
        <div class="meta">
          <thumbnail
            :title="noteAuthorName"
            :src="noteAuthor.thumbnail || noteAuthor.avatar_url"
            :username="noteAuthorName"
            size="20px"
          />
          <div class="date-wrap">
            <span class="fw-medium"> {{ noteAuthorName }} </span>
            <span> {{ $t('CRM_MGMT.TASK.MESSAGE_ADD.ADD') }}
              <img :src="require(`../../../../../assets/crm/${selectTypeTask.icon}.svg`)"  class="activity-icon" width="12" height="12" />
              {{ $t('CRM_MGMT.TASK.MESSAGE_ADD.ACTIVITY') }} {{  this.$t(`CRM_MGMT.TASK.TYPE_TASK.${task.type_activity}`) }}
              {{ $t('CRM_MGMT.TASK.MESSAGE_ADD.TO') }} {{ formatDate(task.scheduled_at) }}</span>
            <span class="fw-medium time-stamp"> {{ readableTime }} </span>
          </div>
        </div>
        <div class="actions">
          <woot-button 
            v-tooltip.left="$t('CRM_MGMT.TASK.EDIT.TITLE')" 
            icon="edit" 
            variant="smooth" 
            color-scheme="secondary"
            :class="deadlineExceeded"
            size="tiny"
            @click="toggleEditModal" 
          />
          <woot-button
            v-tooltip.left="$t('CRM_MGMT.TASK.DELETE.TITLE')"
            variant="smooth"
            size="tiny"
            :class="deadlineExceeded"
            icon="delete"
            color-scheme="secondary"
            @click="toggleDeleteModal"
          />
        </div>
      </div>
      
      <div class="task-body">
        <div class="task-icon">
          <img 
            :src="require(`../../../../../assets/crm/${selectTypeTask.icon}.svg`)"
          />
        </div>
        <div>
          <p v-dompurify-html="formatMessage(task.subject || '')" class="note__content" />
        </div>
      </div>

      <woot-delete-modal
        v-if="showDeleteModal"
        :show.sync="showDeleteModal"
        :on-close="closeDelete"
        :on-confirm="confirmDeletion"
        :title="$t('DELETE_NOTE.CONFIRM.TITLE')"
        :message="$t('CRM_MGMT.TASK.DELETE.MESSAGE')"
        :confirm-text="$t('DELETE_NOTE.CONFIRM.YES')"
        :reject-text="$t('DELETE_NOTE.CONFIRM.NO')"
      />

        <modal-edit-task
          v-if="showEditModal" 
          :show="showEditModal"
          :taskSelected="task"
          @cancel="toggleEditModal"
        />
    </div>
  </template>
  
  <script>
  import Thumbnail from 'dashboard/components/widgets/Thumbnail';
  import timeMixin from 'dashboard/mixins/time';
  import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
  import agentMixin from '../../../../../mixins/agentMixin';
  import ModalEditTask from './ModalEditTask.vue';
  import { mapGetters } from 'vuex';
  import { format, parseISO, isBefore } from 'date-fns';
  
  export default {
    name: 'Task',
    components: {
      Thumbnail,
      ModalEditTask
    },
  
    mixins: [timeMixin, messageFormatterMixin, agentMixin],
  
    props: {
      id: {
        type: Number,
        default: 0,
      },
      task: {
        type: Object,
        default: () => {},
      },
      customOptions: {
        type: Array,
        default: () => [],
      },
      createdAt: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        showDeleteModal: false,
        showEditModal: false,
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        agentList: 'agents/getAgents',
      }),

      deadlineExceeded(){
        const now = new Date();
        const scheduledAt = parseISO(this.task.scheduled_at);
        if(isBefore(scheduledAt, now) && !this.task.status){
          return 'red-card';
        }
        return '';
      },

      selectTypeTask(){
        return this.customOptions.find(obj => obj.value == this.task.type_activity)
      },
      readableTime() {
        return this.dynamicTime(this.createdAt);
      },
      
      userAuthor() {
      if (!this.agentList.length) {
        return null
      }

      return this.agentList?.find(agent => agent.id === this.task?.user_id);
    },
      noteAuthor() {
        return this.userAuthor || {};
      },
      noteAuthorName() {
        return this.userAuthor.name || this.$t('APP_GLOBAL.DELETED_USER');
      },
    },
  
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy HH:mm');
      },
      toggleDeleteModal() {
        this.showDeleteModal = !this.showDeleteModal;
      },
      toggleEditModal() {
        this.showEditModal = !this.showEditModal;
      },
      onDelete() {
        this.$emit('delete', this.id);
      },
      confirmDeletion() {
        this.onDelete();
        this.closeDelete();
      },
      closeDelete() {
        this.showDeleteModal = false;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  // For RTL direction view
  .app-rtl--wrapper {
    .note__content {
      ::v-deep {
        p {
          unicode-bidi: plaintext;
        }
      }
    }
  }

  .task-body {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  .task-icon img {
    height: 20px;
    width: 20px;
  }

  .red-card{
    background: rgba(240, 130, 140, 0.3) !important;
  }
  
  .card-file {
    margin-top: 1rem;
  
    img {
      border-radius: 0.5rem;
      max-width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  
    video {
      max-width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  
    .document {
      font-size: 3rem;
    }
  }
  
  
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: var(--font-size-mini);
  
    .meta {
      display: flex;
      align-items: center;
  
      .date-wrap {
        margin: 0 var(--space-smaller);
        padding: var(--space-micro);
        color: var(--color-body);
      }
    }
    .actions {
      width: 52px;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      visibility: hidden;
  
  
    }
  }
  
  .task-wrap:hover {
    .actions {
      visibility: visible;
    }
  }
  </style>
  