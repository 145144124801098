<template>
  <div class="view-box columns bg-light">
    <settings-header
      button-route="new"
      :icon="icon"
      :header-title="$t(headerTitle)"
      :button-text="$t(headerButtonText)"
      :show-back-button="showBackButton"
      :back-url="backUrl"
      :show-new-button="showNewButton"
      :show-sidemenu-icon="showSidemenuIcon"
    />
    <keep-alive v-if="keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '../..';
import { frontendURL } from '../../../helper/URLHelper';

/* eslint no-console: 0 */
import SettingsHeader from './SettingsHeader';

export default {
  components: {
    SettingsHeader,
  },
  props: {
    headerTitle: { type: String, default: '' },
    headerButtonText: { type: String, default: '' },
    icon: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    newButtonRoutes: {
      type: Array,
      default: () => [],
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (!this.validatePageReport() || !this.supervisorPermissions()) {
      const path = frontendURL(`accounts/${this.accountId}/conversation`);
      router.push({ path });
    }
  },

  methods: {
    validatePageReport() {
      const { name } = this.$store.state.route;
      const { permissions, type_role } = this.currentUser;

      const reportRouter = name.includes('reports');
      const userHasReportsPermission = permissions.reports;
      const isSupervisor = type_role === 'supervisor';

      return userHasReportsPermission || !isSupervisor || !reportRouter;
    },

    supervisorPermissions() {
      const user = this.currentUser.permissions;
      const routerName = this.$store.state.route.name;

      switch (routerName) {
        case 'agent_list':
          return user.agent_settings;
        case 'settings_teams_list':
          return user.team_settings;
        case 'settings_inbox_list':
          return user.inboxes;
        case 'labels_list':
          return user.markers_settings;
        case 'attributes_list':
          return user.custom_attributes_settings;
        case 'automation_list':
          return user.automations_settings;
        case 'macros_wrapper':
          return user.macros_settings;
        case 'settings_integrations':
          return user.integrations;
        case 'settings_applications':
          return user.applications;
        case 'canned_list':
          return user.shortcuts_settings;
        case 'auditlogs_list':
          return user.audit_records;
        default:
          return true;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    currentPage() {
      return this.$store.state.route.name;
    },
    showNewButton() {
      return this.newButtonRoutes.length !== 0 && !this.showBackButton;
    },
  },
};
</script>
