import types from '../mutation-types';
import Vue from 'vue';
import CrmActivitiesApi from '../../api/crmActivities';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isEditing: false,
  },
};

export const getters = {
  getAllActivitiesByContact: (_state) => (contactId) => {
    const records = _state.records.filter(activity => activity.contact_id === contactId);
    return records.sort((r1, r2) => new Date(r1.scheduled_at) - new Date(r2.scheduled_at));
  },  
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  async get({ commit }, { ticketId, contactId }) {
    commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isFetching: true });
    commit(types.CLEAN_ACTIVITIS);
    try {
      const { data } = await CrmActivitiesApi.getActivities(ticketId, contactId);
      commit(types.SET_CRM_ACTIVITIES, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isFetching: false });
    }
  },

  async filterByType({ commit }, { ticketId, type }) {
    commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isFetching: true });
    try {
      const { data } = await CrmActivitiesApi.getActivitiesByType(ticketId, type);
      commit(types.SET_CRM_ACTIVITIES, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isFetching: false });
    }
  },

  async create({ commit }, payload) {
    commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isCreating: true });
    try {
      const { data } = await CrmActivitiesApi.createActivity(payload);
      commit(types.ADD_CRM_ACTIVITY, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isCreating: false });
    }
  },

  async delete({ commit }, activityId) {
    commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isDeleting: true });
    try {
      await CrmActivitiesApi.deleteActivity(activityId);
      commit(types.DELETE_CRM_ACTIVITY, activityId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isDeleting: false });
    }
  },

  async update({ commit }, payload) {
    commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isEditing: true });
    try {
      const { data } = await CrmActivitiesApi.updateActivity(payload);
      commit(types.EDIT_CRM_ACTIVITY, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CRM_ACTIVITIES_UI_FLAG, { isEditing: false });
    }
  },
};

export const mutations = {
  [types.SET_CRM_ACTIVITIES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.CLEAN_ACTIVITIS](state) {
    state.records = [];
  },

  [types.SET_CRM_ACTIVITIES]($state, data) {
    $state.records = data;
  },
  [types.ADD_CRM_ACTIVITY]($state, data) {
    $state.records.push(data);
  },
  [types.DELETE_CRM_ACTIVITY]($state, activityId) {
    $state.records = $state.records.filter(activity => activity.id !== activityId);
  },
  [types.EDIT_CRM_ACTIVITY](state, data) {
    const index = state.records.findIndex(activity => activity.id === data.id);
    if (index !== -1) {
      Vue.set(state.records, index, data);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
