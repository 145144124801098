<template>
  <div class="task-view">
    <add-task @add="onAdd" />
    <div class="task-icon">
      <h2>{{ $t('CRM_MGMT.TASK.FILTER_TYPE.TITLE') }}</h2>
      <div class="card-filters">
        <ul>
          <li 
            v-for="task in customOptions" 
            :key="task.id" 
            @click="getActivitesByStatus(task.value)"
            v-tooltip.top="task.label"
            :class="{ active: activeFilter === task.value }"  
          >
            <img
              :src="require(`../../../../../assets/crm/${task.icon}.svg`)"
            />
          </li>
        </ul>
        <woot-button
          v-if="activeFilter"
          class="clear secondary"
          v-tooltip.top="$t('CONTACTS_FILTER.CLEAR_ALL_FILTERS')"
          icon="dismiss"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="fetchActivities"
        />
      </div>
    </div>
    <div v-if="pendingActivities.length" >
      <h2>{{ $t('CRM_MGMT.TASK.UPCOMING_ACTIVITIES') }}</h2>
      <task
        v-for="task in pendingActivities"
        :id="task.id"
        :key="task.id"
        :task="task"
        :custom-options="customOptions"
        :created-at="task.timestamp"
        @delete="onDelete"
      />
    </div>

    <div v-if="completedActivities.length">
      <h2>{{ $t('CRM_MGMT.TASK.HISTORY') }}</h2>
      <task
        v-for="task in completedActivities"
        :id="task.id"
        :key="task.id"
        :task="task"
        :custom-options="customOptions"
        :created-at="task.timestamp"
        @delete="onDelete"
      />
    </div>

    <div v-if="isFetching" class="text-center p-normal fs-default">
      <spinner size="" />
      <span>{{ $t('CRM_MGMT.TASK.FETCHING_TASK') }}</span>
    </div>
    <div v-else-if="!activities.length" class="text-center p-normal fs-default">
      <span>{{ $t('CRM_MGMT.TASK.NOT_AVAILABLE') }}</span>
    </div>
  </div>
</template>

<script>
import AddTask from './AddTask.vue';
import Task from './Task.vue';
import Spinner from 'shared/components/Spinner';
import { mapGetters } from 'vuex';
export default {
  name: 'TasksView',
  components: {
    AddTask,
    Task,
    Spinner,
  },
  data() {
    return {
      customOptions: [
        { value: 'call', label: this.$t('CRM_MGMT.TASK.TYPE_TASK.call') , icon: 'call' },
        { value: 'email', label: this.$t('CRM_MGMT.TASK.TYPE_TASK.email') , icon: 'email' },
        { value: 'visit', label: this.$t('CRM_MGMT.TASK.TYPE_TASK.visit'), icon: 'house' },
        { value: 'task', label: this.$t('CRM_MGMT.TASK.TYPE_TASK.task'), icon: 'task' },
        { value: 'lunch', label: this.$t('CRM_MGMT.TASK.TYPE_TASK.lunch'), icon: 'food' },
        { value: 'whatsapp', label: this.$t('CRM_MGMT.TASK.TYPE_TASK.whatsapp'), icon: 'whatsapp' },
        { value: 'meeting', label: this.$t('CRM_MGMT.TASK.TYPE_TASK.meeting'), icon: 'people' },
      ],
      activeFilter: null
    }
  },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
    isTicket: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    this.fetchActivities();
  },

  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    uiFlags: 'crmActivities/getUIFlags'
    }),
    activities() {
      return this.$store.getters['crmActivities/getAllActivitiesByContact'](
        this.contactId
      );
    },


    completedActivities(){
      return this.activities.filter(task => task.status)
    },
    pendingActivities(){
      return this.activities.filter(task => !task.status)
    },

    isFetching(){
        return this.uiFlags.isFetching;
    }
  },

  methods: {
    onAdd(activity) {
      const { contactId, ticket } = this;

      const payload = {
        ...activity,
        contact_id: contactId,
        ticket_id: ticket.id,
        user_id: this.currentUser.id,
      };

      this.$store.dispatch('crmActivities/create', payload);
    },
    onDelete(activityId) {
      this.$store.dispatch('crmActivities/delete', activityId);
    },


    getActivitesByStatus(type){
      this.activeFilter = type;
      const { ticket } = this;
      this.$store.dispatch('crmActivities/filterByType', {
        ticketId: ticket.id,
        type
      });
    },

    fetchActivities() {
      const { contactId, ticket } = this;
      if (contactId){
        this.$store.dispatch('crmActivities/get', {
          contactId,
          ticketId: ticket.id,
        });
      }
      this.activeFilter = null
    },
  },
};
</script>

<style>

.task-view h2{
  font-weight: 300;
}

.v-select {
  font-size: 1.4rem;
  width: 100%;
  height: 40px;
}

.v-select input {
  height: auto !important;
}

.vs--searchable .vs__dropdown-toggle, .date-picker .mx-datepicker, .mx-input-wrapper  {
  height: 40px !important;
}

.v-select .vs__control {
  padding: 0 10px;
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.v-select .vs__selected {
  font-size: 1.4rem !important;
  line-height: 1.4rem;
}

.v-select .vs__dropdown-option {
  font-size: 1.4rem;
}

.task-icon ul {
  display: flex;
  gap: 1rem;
  margin: 0;
  margin-bottom: 1rem;
}

.task-icon li{
  margin: 0;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.task-icon img {
  height: 25px;
  width: 25px;
}

.task-icon li.active img {
  filter: brightness(0) saturate(100%) invert(58%) sepia(14%) saturate(3500%) hue-rotate(160deg) brightness(90%) contrast(90%);
}

.card-filters {
  display: flex;
  gap: 1rem;
}

</style>