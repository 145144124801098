<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CRM_MGMT.TASK.EDIT.TITLE')"
        :header-content="''"
      />

      <div class="card">
        <div class="card-form card-1 card-select">
          <p>{{ $t('CRM_MGMT.TASK.FORM.SUBJECT') }}</p>
          <input
            type="text"
            placeholder="Assunto da tarefa"
            v-model="subject"
          />
        </div>

        <div class="card-form card-2">
          <p>
            {{ $t('CRM_MGMT.TASK.FORM.SCHEDULING') }}
          </p>
          <woot-date-time-picker
            :value="scheduledAt"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER_DATE')"
            @change="onChangeDate"
          />
        </div>

        <div class="card-form card-1">
          <p>
            {{ $t('CRM_MGMT.TASK.FORM.TASK_TYPE') }}
          </p>
          <v-select
            v-model="task"
            :options="customOptions"
            class="icon-select"
            label="label"
            :reduce="option => option.value"
            :clearable="false"
          >
            <template #option="option">
              <img
                :src="require(`../../../../../assets/crm/${option.icon}.svg`)"
                alt=""
              />
              {{ option.label }}
            </template>
          </v-select>
        </div>
        <div class="card-form card-2">
          <p>{{ $t('CRM_MGMT.TASK.FORM.ASSIGNED') }}</p>
          <v-select
            v-model="assigneeId"
            :options="optionsAgent"
            class="icon-select"
            label="label"
            :reduce="option => option.value"
            :clearable="false"
          />
        </div>
        <div class="row"></div>

        <div class="status-task">
          <p>{{ $t('CRM_MGMT.TASK.FORM.STATUS') }}</p>

          <p>
            <input
              id="pending"
              type="checkbox"
              v-model="statusView"
              @change="setStatus('pending')"
            />
            {{ $t('CRM_MGMT.TASK.FORM.PENDING') }}
          </p>

          <p>
            <input
              id="completed"
              type="checkbox"
              v-model="status"
              @change="setStatus('completed')"
            />
            {{ $t('CRM_MGMT.TASK.FORM.COMPLETED') }}
          </p>
        </div>

        <div class="footer">
          <woot-button
            color-scheme="warning"
            :is-disabled="disabledSubmit"
            :title="$t('NOTES.SAVE.BUTTON')"
            @click="onAdd"
          >
            {{ $t('NOTES.SAVE.BUTTON') }} (⌘⏎)
          </woot-button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { hasPressedCommandAndEnter } from 'shared/helpers/KeyboardHelpers';

export default {
  name: 'ModalEditTask',

  components: { vSelect, WootDateTimePicker },
  data() {
    return {
      task: this.taskSelected.type_activity,
      scheduledAt: new Date(this.taskSelected.scheduled_at),
      assigneeId: this.taskSelected.assignee_id,
      subject: this.taskSelected.subject,
      status: this.taskSelected.status,
      statusView: !this.taskSelected.status,
      customOptions: [
        {
          value: 'call',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.call'),
          icon: 'call',
        },
        {
          value: 'email',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.email'),
          icon: 'email',
        },
        {
          value: 'visit',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.visit'),
          icon: 'house',
        },
        {
          value: 'task',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.task'),
          icon: 'task',
        },
        {
          value: 'lunch',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.lunch'),
          icon: 'food',
        },
        {
          value: 'whatsapp',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.whatsapp'),
          icon: 'whatsapp',
        },
        {
          value: 'meeting',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.meeting'),
          icon: 'people',
        },
      ],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    taskSelected: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      agentList: 'agents/getAgents',
    }),
    optionsAgent() {
      return this.agentList
        .filter(agent => agent.id !== this.currentUser.id)
        .map(({ id, name }) => ({ value: id, label: name }));
    },
    disabledSubmit() {
      if (!this.subject || !this.scheduledAt || !this.task) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    document.addEventListener('keydown', this.onMetaEnter);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onMetaEnter);
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },

    onMetaEnter(e) {
      if (hasPressedCommandAndEnter(e)) {
        e.preventDefault();
        this.onAdd();
      }
    },
    async onAdd() {
      if (this.scheduledAt !== this.taskSelected.scheduled_at) {
        this.taskSelected.notification_count = 0;
      }

      const payload = {
        ...this.taskSelected,
        scheduled_at: this.scheduledAt,
        subject: this.subject,
        type_activity: this.task,
        status: this.status,
        assignee_id: this.assigneeId,
      };
      await this.$store.dispatch('crmActivities/update', payload);
      this.setDefault();
      this.onCancel();
    },

    setDefault() {
      this.scheduledAt = null;
      this.subject = '';
      this.task = null;
      this.status = false;
      this.statusView = true;
    },

    onChangeDate(value) {
      this.scheduledAt = value;
    },

    setStatus(type) {
      if (type === 'pending') {
        this.status = false;
        this.statusView = true;
      } else if (type === 'completed') {
        this.status = true;
        this.statusView = false;
      }
    },

    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style scoped>
.card-form {
  margin-bottom: 1.6rem;
}

.icon-select select {
  width: 100%;
  padding: 10px;
}

.icon-select img {
  height: 20px;
  margin-right: 0.5rem;
}
.footer {
  display: flex;
  justify-content: flex-end;
  align-content: center !important;
  gap: 1rem;
  width: 100%;
}

.status-task {
  display: flex;
  gap: 1rem;
}
</style>