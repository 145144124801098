import ApiClient from './ApiClient';

class CrmActivitiesApi extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  async getActivities(ticket_id, contact_id) {
    return await axios.get(`${this.url}crm_activities?ticket_id=${ticket_id}&contact_id=${contact_id}`);
  }
  async getActivitiesByType(ticket_id, type) {
    return await axios.get(`${this.url}crm_activities/filter_by_type?ticket_id=${ticket_id}&type_activity=${type}`);
  }

  async createActivity(payload) {
    return await axios.post(`${this.url}crm_activities`, payload);
  }

  async updateActivity(payload) {
    return await axios.put(`${this.url}crm_activities/${payload.id}`, payload);
  }

  async deleteActivity(id) {
    return await axios.delete(`${this.url}crm_activities/${id}`);
  }

}

export default new CrmActivitiesApi();