<template>
  <transition name="modal-fade">
    <div
      v-if="show"
      :class="modalClassName"
      transition="modal"
      @click="onBackDropClick"
    >
      <div
        :class="modalContainerClassName"
        :style="{
          zIndex: customZIndex !== undefined ? customZIndex : defaultZIndex,
        }"
        @click.stop
      >
        <woot-button
          color-scheme="secondary"
          icon="dismiss"
          variant="clear"
          class="modal--close"
          @click="close"
        />
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    customZIndex: {
      type: [Number, String],
      default: undefined,
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    show: Boolean,
    onClose: {
      type: Function,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'centered',
    },
    size: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultZIndex: 1000,
    };
  },
  computed: {
    modalContainerClassName() {
      let className = 'modal-container skip-context-menu';
      if (this.fullWidth) {
        return `${className} modal-container--full-width`;
      }

      return `${className} ${this.size}`;
    },
    modalClassName() {
      const modalClassNameMap = {
        centered: '',
        'right-aligned': 'right-aligned',
      };

      return `modal-mask skip-context-menu  ${modalClassNameMap[
        this.modalType
      ] || ''} ${this.customClass}`;
    },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.code === 'Escape') {
        this.onClose();
      }
    });
  },
  methods: {
    close() {
      this.onClose();
    },
    onBackDropClick() {
      if (!this.persistent && this.closeOnBackdropClick) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container--full-width {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.modal-mask.right-aligned {
  justify-content: flex-end;

  .modal-container {
    border-radius: 0;
    height: 100%;
    width: 48rem;
  }
}

.modal-big {
  width: 60%;
}

.modal-medium {
  padding: 1.5%;
  width: 60%;
  height: 50%;
}

.small {
  width: 45%;
  height: 50%;
}

.tiny {
  width: 20%;
}

.modal-fit {
  width: fit-content;
  height: fit-content;
  max-height: 70%;
}

.modal-xl {
  padding: 3%;
  width: 80%;
  height: 80%;
}
</style>
