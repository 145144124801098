<template>
  <div class="card note-wrap">
    <div class="header">
      <div class="meta">
        <thumbnail
          :title="noteAuthorName"
          :src="noteAuthor.thumbnail || noteAuthor.avatar_url"
          :username="noteAuthorName"
          size="20px"
        />
        <div class="date-wrap">
          <span class="fw-medium"> {{ noteAuthorName }} </span>
          <span> {{ $t('NOTES.LIST.LABEL') }} </span>
          <span class="fw-medium time-stamp"> {{ readableTime }} </span>
        </div>
      </div>
      <div v-if="isPermissinDeleteObsContacts" class="actions">
        <a :href="note.attachment_url" target="_blank" rel="noopener noreferrer">
          <woot-button
            v-if="note.attachment_url" 
            icon="arrow-download"
            emoji="arrow-download"
            v-tooltip.left="$t('CONVERSATION.DOWNLOAD') "
            color-scheme="secondary"
            variant="smooth"
            size="tiny"
          />
        </a>

        <woot-button
          v-tooltip.left="$t('NOTES.CONTENT_HEADER.DELETE')"
          variant="smooth"
          :disabled="!isPermissinDeleteObsContacts"
          size="tiny"
          icon="delete"
          color-scheme="secondary"
          @click="toggleDeleteModal"
        />
      </div>
      <woot-delete-modal
        v-if="showDeleteModal"
        :show.sync="showDeleteModal"
        :on-close="closeDelete"
        :on-confirm="confirmDeletion"
        :title="$t('DELETE_NOTE.CONFIRM.TITLE')"
        :message="$t('DELETE_NOTE.CONFIRM.MESSAGE')"
        :confirm-text="$t('DELETE_NOTE.CONFIRM.YES')"
        :reject-text="$t('DELETE_NOTE.CONFIRM.NO')"
      />
    </div>

    <div v-if="note.attachment_url" class="card-file">
      <img v-if="typeFile == 'image'" :src="note.attachment_url" alt="">

      <audio v-if="typeFile === 'audio'" :src="note.attachment_url" controls />

      <video v-if="typeFile === 'video'" :src="note.attachment_url" controls
        playsInline
        class="modal-video skip-context-menu">
      </video>


      <div v-if="typeFile === 'document' || typeFile === 'unknown'" class="document">📄</div>
    </div>

    <p v-dompurify-html="formatMessage(note.content || '')" class="note__content" />
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import timeMixin from 'dashboard/mixins/time';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import agentMixin from '../../../mixins/agentMixin';

export default {
  components: {
    Thumbnail,
  },

  mixins: [timeMixin, messageFormatterMixin, agentMixin],

  props: {
    id: {
      type: Number,
      default: 0,
    },
    note: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    createdAt: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },
  computed: {
    readableTime() {
      return this.dynamicTime(this.createdAt);
    },
    noteAuthor() {
      return this.user || {};
    },
    noteAuthorName() {
      return this.noteAuthor.name || this.$t('APP_GLOBAL.DELETED_USER');
    },

    typeFile() {
    const attachmentUrl = this.note?.attachment_url?.toLowerCase();

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.flv'];
    const audioExtensions = ['.mp3', '.wav', '.aac', '.flac', '.ogg'];
    const docExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt'];

    const checkFileType = (extensions) => extensions.some(ext => attachmentUrl.endsWith(ext));

    if (checkFileType(imageExtensions)) return 'image';
    if (checkFileType(audioExtensions)) return 'audio';
    if (checkFileType(videoExtensions)) return 'video';
    if (checkFileType(docExtensions)) return 'document';

    return 'unknown';
  }
  },

  methods: {
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    onDelete() {
      this.$emit('delete', this.id);
    },
    confirmDeletion() {
      this.onDelete();
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// For RTL direction view
.app-rtl--wrapper {
  .note__content {
    ::v-deep {
      p {
        unicode-bidi: plaintext;
      }
    }
  }
}


.card-file {
  margin-top: 1rem;

  img {
    border-radius: 0.5rem;
    max-width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  video {
    max-width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  .document {
    font-size: 3rem;
  }
}

.unsupported {
  display: flex;
  align-items: center;
  h4 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  img {
    height: 20px;
  }
}
.note__content {
  margin-top: var(--space-normal);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: var(--font-size-mini);

  .meta {
    display: flex;
    align-items: center;

    .date-wrap {
      margin: 0 var(--space-smaller);
      padding: var(--space-micro);
      color: var(--color-body);
    }
  }
  .actions {
    width: 52px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    visibility: hidden;


  }
}

.note-wrap:hover {
  .actions {
    visibility: visible;
  }
}
</style>
