<template>
  <header class="app-header">
    <div @click="go_home">
      <div class="card-logo">
        <div class="card-logo-1">
          <img :src="require('../../assets/pingo')" />
        </div>
        <div class="card-logo-2">
          <img :src="require('../../assets/logoInterativa')" />
        </div>
      </div>
    </div>

    <div v-if="showSearchField" class="filters">
      <div
        :class="{ focused: isFocused, blur: !isFocused }"
        class="field-search"
        @click="focusInputSearch"
      >
        <div class="icon flex">
          <fluent-icon
            :class="{ focused: isFocused, blur: !isFocused }"
            icon="search"
            size="20"
          />
        </div>

        <input
          ref="searchInput"
          type="text"
          :class="{ focused: isFocused, blur: !isFocused }"
          :placeholder="
            pageDefault
              ? $t('CONVERSATION.SEARCH_MESSAGES_CONTACT')
              : $t('CONVERSATION.SEARCH_CONTACT')
          "
          :value="searchQuery"
          @input="debounceSearch"
          @focus="handleFocusSearch"
          @blur="handleBlurSearch"
        />
      </div>

      <button
        v-tooltip.bottom="
          pageDefault
            ? $t('FILTER.TOOLTIP_LABEL')
            : $t('CONTACTS_FILTER.TOOLTIP_LABEL')
        "
        class="btn-filter"
        @click="showFilterAdvanced"
      >
        <emoji-or-icon
          class="icon"
          emoji="filter"
          icon="filter"
          icon-size="35"
        />
      </button>
    </div>
    <div class="menu-header">
      <div
        v-tooltip.bottom="$t(`NOTIFICATIONS_PAGE.HEADER`)"
        class="card-icon"
        @click="openNotificationPanel"
      >
        <notification-bell class="c-p" />
      </div>

      <div v-tooltip.bottom="'Status'" class="card-status">
        <status-details @toggle-menu="toggleOptions" />
        <options-config-user
          :show="showOptionsMenu"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @close="toggleOptions"
        />
      </div>
      <div>
        <agent-details @toggle-menu="toggleOptionsStatus" />
        <options-menu
          :show="showOptionsMenuStatus"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @key-shortcut-modal="$emit('open-key-shortcut-modal')"
          @close="toggleOptionsStatus"
        />
      </div>

      <notification-panel
        v-if="isNotificationPanel"
        @close="closeNotificationPanel"
      />
    </div>

    <woot-modal
      v-if="queryConversation"
      :show.sync="modalFilters"
      :on-close="closeModalFilters"
      size="small"
      custom-class="search-modal"
      @close="closeModalFilters"
    >
      <search-view @closeModal="closeModalFilters" />
    </woot-modal>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../helper/URLHelper';

import SearchView from '../../modules/search/components/SearchView.vue';
import AgentDetails from '../../components/layout/sidebarComponents/AgentDetails.vue';
import OptionsMenu from '../../components/layout/sidebarComponents/OptionsMenu.vue';
import NotificationBell from '../../components/layout/sidebarComponents/NotificationBell.vue';
import OptionsConfigUser from '../../components/layout/sidebarComponents/OptionsConfigUser.vue';
import StatusDetails from '../../components/layout/sidebarComponents/StatusDetails.vue';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel';
import router from '..';
import { CONVERSATION_EVENTS } from '../../helper/AnalyticsHelper/events';
import EmojiOrIcon from 'shared/components/EmojiOrIcon';

export default {
  name: 'HeaderApp',
  components: {
    AgentDetails,
    OptionsMenu,
    NotificationBell,
    OptionsConfigUser,
    StatusDetails,
    NotificationPanel,
    SearchView,
    EmojiOrIcon,
  },
  data() {
    return {
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
      toggleContactsModal: false,
      displayLayoutType: '',
      showOptionsMenu: false,
      showOptionsMenuStatus: false,
      searchQuery: '',
      isFocused: false,
      pageDefault: true,
      modalFilters: false,
      showSearchField: false,
      showAdvancedFilters: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
      queryConversation: 'conversationSearch/getQueryConversation',
    }),
    directConfig() {
      return frontendURL(`accounts/${this.accountId}/settings/agents/list`);
    },
  },
  watch: {
    queryConversation: {
      handler(newValue) {
        this.searchQuery = newValue;
        if (newValue === 'close-modal') {
          this.closeModalFilters();
        }
      },
      immediate: true,
    },

    $route: {
      handler(newValue) {
        const { name: pathName } = newValue;
        const isContactPage = this.checkRouteContact(pathName);
        const showField = this.checkRoute(pathName);

        if (isContactPage) {
          this.pageDefault = false;
        } else {
          this.pageDefault = true;
        }

        if (showField) {
          this.showSearchField = true;
        } else {
          this.showSearchField = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    checkRoute(pathName) {
      if (pathName.includes('unattended')) return true; // all unattended

      switch (pathName) {
        case 'home':
        case 'inbox_dashboard':
        case 'contacts_dashboard':
        case 'label_conversations':
        case 'conversation_mentions':
        case 'conversations_through_label':
        case 'inbox_conversation':
        case 'conversation_through_mentions':
        case 'wallet_contacts_dashboard':
        case 'contacts_segments_dashboard':
        case 'contacts_labels_dashboard':
        case 'conversation_through_inbox':
        case 'folder_conversations':
        case 'conversations_through_folders':
          return true;
        default:
          return false;
      }
    },
    checkRouteContact(pathName) {
      switch (pathName) {
        case 'contacts_dashboard':
        case 'wallet_contacts_dashboard':
        case 'contacts_labels_dashboard':
        case 'contacts_segments_dashboard':
          return true;
        default:
          return false;
      }
    },

    go_home() {
      if (this.currentUser.role == 'agent') {
        const path = frontendURL(`accounts/${this.accountId}/conversation`);
        router.push({ path });
      } else {
        const path = frontendURL(`accounts/${this.accountId}/dashboard`);
        router.push({ path });
      }
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.isNotificationPanel = true;
      if (this.$route.name !== 'notifications_index') {
        this.$emit('open-notification-panel');
      }
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleOptionsStatus() {
      this.showOptionsMenuStatus = !this.showOptionsMenuStatus;
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.$emit('open-key-shortcut-modal');
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },

    handleFocusSearch() {
      this.isFocused = true;
    },

    handleBlurSearch() {
      this.isFocused = false;
    },

    focusInputSearch() {
      this.$refs.searchInput.focus();
    },

    debounceSearch(e) {
      this.searchQuery = e.target.value;
      this.modalFilters = true;
      this.$store.dispatch('conversationSearch/setQuery', e.target.value);
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.searchQuery.length > 2 || this.searchQuery.match(/^[0-9]+$/)) {
          this.onSearch(this.searchQuery);
        }
      }, 500);
    },

    onSearch(q) {
      this.selectedTab = 'all';
      this.query = q;
      if (!q) {
        this.$store.dispatch('conversationSearch/clearSearchResults');
        this.closeModalFilters();
        return;
      }
      this.$track(CONVERSATION_EVENTS.SEARCH_CONVERSATION);
      this.$store.dispatch('conversationSearch/fullSearch', { q });
    },

    closeModalFilters() {
      this.$store.dispatch('conversationSearch/setQuery', '');
      this.modalFilters = false;
    },

    showFilterAdvanced() {
      let status = 0;
      if (this.pageDefault) {
        status = 1;
      } else {
        status = 2;
      }
      this.$store.dispatch('setStatusFilterConversationEndContact', { status });
    },
  },
};
</script>

<style scoped>
.app-header {
  display: flex;
  background: var(--w-950);
  height: 55px;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  border-bottom: 2px solid var(--b-950);
}

.card-logo {
  display: flex;
  align-items: center;
}

.card-icon {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.9rem;
  height: 40px;
  width: 40px;
  border: 1px solid transparent !important;
}

.card-status {
  padding: 5px;
}

.thumbnailBoxClass {
  height: 20px !important;
  width: 20px !important;
}

.card-icon:hover,
.card-icon:active {
  background: var(--g-20) !important;
}

.c-p {
  cursor: pointer;
}

.card-logo img {
  height: 30px;
}

.card-logo-1 {
  width: 40px;
}

.card-logo-2 {
  width: 200px;
  margin-left: 6.6px;
}

.menu-header {
  display: flex;
  gap: 30px;
  height: 100%;
  align-items: center;
}

.filters {
  display: flex;
  width: 45%;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.field-search {
  border: 1px solid var(--w-980);
  border-radius: 20px;
  height: 35px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field-search input {
  margin: 0;
  height: 30px;
  width: 80%;
  background: transparent;
  border: none;
  font-size: 1.4rem;
}

.btn-filter {
  color: var(--blur);
  background: transparent;
  cursor: pointer;
}

.btn-filter :hover {
  color: var(--focused) !important;
}

.field-search input::placeholder {
  color: var(--focused);
}

.focused {
  color: var(--focused) !important;
  background: var(--w-960);
}
.focused::placeholder {
  color: var(--focused) !important;
}

.blur {
  color: var(--blur) !important;
}
.blur::placeholder {
  color: var(--blur) !important;
}

input:focus {
  box-shadow: none !important;
}

.search-modal {
  align-items: flex-start !important;
  margin-top: 55px !important;
}

.small {
  width: 40% !important;
}

.status-user p {
  margin: 0;
}

.fi {
  color: var(--s-50);
  font-size: 17px;
  display: flex;
}
.flex {
  display: flex;
}
</style>
