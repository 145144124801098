<template>
  <div class="card">
    <p class="title">{{ $t('CRM_MGMT.TASK.FORM.TITLE') }}</p>
    <div class="row">
      <div class="card-form card-1 card-select">
        <p>{{ $t('CRM_MGMT.TASK.FORM.SUBJECT') }}</p>
        <input type="text" placeholder="Assunto da tarefa" v-model="subject" />
      </div>

      <div class="card-form card-2">
        <p>
          {{ $t('CRM_MGMT.TASK.FORM.SCHEDULING') }}
        </p>
        <woot-date-time-picker
          :value="scheduledAt"
          :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
          :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER_DATE')"
          @change="onChangeDate"
        />
      </div>
    </div>

    <div class="row">
      <div class="card-form card-1">
        <p>
          {{ $t('CRM_MGMT.TASK.FORM.TASK_TYPE') }}
        </p>
        <v-select
          v-model="task"
          :options="customOptions"
          class="icon-select"
          label="label"
          :reduce="option => option.value"
          :clearable="false"
        >
          <template #option="option">
            <img
              :src="require(`../../../../../assets/crm/${option.icon}.svg`)"
              alt=""
            />
            {{ option.label }}
          </template>
        </v-select>
      </div>

      <div class="card-form card-2">
        <p>{{ $t('CRM_MGMT.TASK.FORM.ASSIGNED') }}</p>
        <v-select
          v-model="assigneeId"
          :options="optionsAgent"
          class="icon-select"
          label="label"
          :reduce="option => option.value"
          :clearable="false"
        />
      </div>
    </div>

    <div class="row">
      <div class="status-task">
        <p>{{ $t('CRM_MGMT.TASK.FORM.STATUS') }}</p>

        <p >
            <input id="pending" type="checkbox" v-model="statusView" @change="setStatus('pending')" />
            {{ $t('CRM_MGMT.TASK.FORM.PENDING') }}
        </p>

        <p>
            <input id="completed" type="checkbox" v-model="status" @change="setStatus('completed')" />
            {{ $t('CRM_MGMT.TASK.FORM.COMPLETED') }}
        </p>
      </div>
    </div>

    <div class="footer">
      <woot-button
        color-scheme="warning"
        :is-disabled="disabledSubmit"
        :title="$t('NOTES.ADD.TITLE')"
        @click="onAdd"
      >
        {{ $t('NOTES.ADD.BUTTON') }} (⌘⏎)
      </woot-button>
    </div>
  </div>
</template>
  
<script>
import vSelect from 'vue-select';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { hasPressedCommandAndEnter } from 'shared/helpers/KeyboardHelpers';
import { mapGetters } from 'vuex';

export default {
  components: { vSelect, WootDateTimePicker },
  data() {
    return {
      task: null,
      scheduledAt: null,
      assigneeId: null,
      subject: '',
      status: false,
      statusView: true,
      customOptions: [
        {
          value: 'call',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.call'),
          icon: 'call',
        },
        {
          value: 'email',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.email'),
          icon: 'email',
        },
        {
          value: 'visit',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.visit'),
          icon: 'house',
        },
        {
          value: 'task',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.task'),
          icon: 'task',
        },
        {
          value: 'lunch',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.lunch'),
          icon: 'food',
        },
        {
          value: 'whatsapp',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.whatsapp'),
          icon: 'whatsapp',
        },
        {
          value: 'meeting',
          label: this.$t('CRM_MGMT.TASK.TYPE_TASK.meeting'),
          icon: 'people',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      agentList: 'agents/getAgents',
    }),
    optionsAgent() {
      return this.agentList
        .filter(agent => agent.id !== this.currentUser.id)
        .map(({ id, name }) => ({ value: id, label: name }));
    },
    disabledSubmit() {
      if (!this.subject || !this.scheduledAt || !this.task) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onMetaEnter);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onMetaEnter);
  },
  methods: {
    onMetaEnter(e) {
      if (hasPressedCommandAndEnter(e)) {
        e.preventDefault();
        this.onAdd();
      }
    },
    onAdd() {
      const payload = {
        scheduled_at: this.scheduledAt,
        subject: this.subject,
        type_activity: this.task,
        status: this.status,
        assignee_id: this.assigneeId,
      };
      this.$emit('add', payload);
      this.setDefault();
    },

    setDefault() {
      this.scheduledAt = null;
      this.subject = '';
      this.task = null;
      this.status = false;
      this.statusView = true;
      this.assigneeId = null;
    },

    onChangeDate(value) {
      this.scheduledAt = value;
    },

    setStatus(type) {
      if (type === 'pending') {
        this.status = false;
        this.statusView = true;
      } else if (type === 'completed') {
        this.status = true;
        this.statusView = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-1 {
  width: 50%;
}
.card-2 {
  width: 48%;
  margin-left: 1rem;
}
.card-form {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.card {
  overflow: unset !important;

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }
}

.date-picker .mx-datepicker,
.date-picker .mx-input {
  height: 50px !important;
}

input {
  margin: 0 !important;
}

.icon-select select {
  width: 100%;
  padding: 10px;
}

.card-form {
  margin-bottom: 1.6rem;
}
.icon-select input {
  width: 100% !important;
}

.v-select {
  font-size: 1.4rem;
  width: 60%;
}

input[type='search'] {
  font-size: 1.4rem !important;
}

.v-select .vs__selected {
  font-size: 1.4rem !important;
}

.v-select .vs__selected::placeholder {
  font-size: 1.4rem;
}

.v-select .vs__dropdown-option {
  font-size: 1.4rem;
}

.icon-select img {
  height: 20px;
  margin-right: 0.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-content: center !important;
  gap: 1rem;
  width: 100%;
}

.status-task {
  display: flex;
  gap: 1rem;
}
</style>
  