import ApiClient from './ApiClient';

class WhatsappTemplateApi extends ApiClient {
    constructor() {
        super('', { accountScoped: true });
    }
    setTemplateMedia(payload) {
        return axios.post(`${this.url}/whatsapp_templates`, payload);
      }

}

export default new WhatsappTemplateApi();